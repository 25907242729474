export const chartColors = {
    default: {
        primary: '#03c9e9',
        info: '#03c9e9',
        danger: '#FF3860',
        warning: '#75ff02'
    }
}

export const baseChartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    responsive: true
}

export const chartOptionsMain = {
    ...baseChartOptions,
    tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest'
    },
    scales: {
        yAxes: [{
            gridLines: {
                drawBorder: true,
                color: 'rgba(29,140,248,0.0)',
                zeroLineColor: 'transparent'
            },
            ticks: {
                //padding: 30,
                //fontColor: '#9a9a9a'
            }
        }],

        xAxes: [{
            gridLines: {
                drawBorder: true,
                color: 'rgba(225,78,202,0.1)',
                zeroLineColor: 'transparent'
            },
            ticks: {
                fontColor: '#9a9a9a'
            }
        }]
    }
}
