<template> 
    <section>
        <div class="columns is-mobile is-multiline">
            <div class="column is-half-mobile">
                <b-button expanded
                          tag="router-link"
                          icon-left="clipboard-plus-outline"
                          :to="{ name: 'portalconvenio' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.GUIAS')}}
                </b-button>
            </div>

            <div class="column is-half-mobile" v-if="!usuario.escondePrecos">
                <b-button expanded
                          tag="router-link"
                          icon-left="cash"
                          :to="{ name: 'portalorcamento' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.ORCAMENTO')}}
                </b-button>
            </div>

            <div class="column is-half-mobile" v-if="(usuario.convenioPodeIncluirGuia && !usuario.escondeIncluirGuia)">
                <b-button expanded
                          tag="router-link"
                          icon-left="plus-circle-outline"
                          :to="{ name: 'portallistarpaciente' }"
                          active-class="is-primary">
                        {{$t('NAVBARCONVENIO.INCLUIRGUIA')}}
                </b-button>
            </div>

            <div class="column is-half-mobile">
                <b-button expanded
                          tag="router-link"
                          icon-left="send-circle-outline"
                          :to="{ name: 'portallote' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.ENVIARLOTE')}}
                </b-button>
            </div>

            <div class="column is-hidden-touch">
                <b-button expanded
                          tag="router-link"
                          icon-left="format-list-bulleted"
                          :to="{ name: 'portalcatalogoexames' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.CATALOGO')}} <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.DEEXAMES')}}</span>
                </b-button>
            </div>

            <div class="column is-hidden-touch">
                <b-button expanded
                          tag="router-link"
                          icon-left="flask-outline"
                          :to="{ name: 'portalamostras' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.AMOSTRAS')}}
                </b-button>
            </div>

            <div class="column is-hidden-touch">
                <b-button expanded
                          tag="router-link"
                          icon-left="test-tube"
                          :to="{ name: 'portalrelacaoexames' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.EXAMES')}}
                </b-button>
            </div>

            <div class="column is-hidden-touch" v-if="usuario.habilitarEstoque">
                <b-button expanded
                          tag="router-link"
                          icon-left="archive"
                          :to="{ name: 'portalestoque' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.ESTOQUE')}}
                </b-button>
            </div>

            <div class="column is-hidden-touch">
                <b-button expanded
                          tag="router-link"
                          icon-left="file-document-outline"
                          :to="{ name: 'portalresultados' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.RESULTADOS')}}
                </b-button>
            </div>

        </div>

        <div class="columns is-mobile is-gapless is-hidden-desktop">
            <div class="column is-3">
                <b-button expanded size="is-small"
                          tag="router-link"
                          icon-left="format-list-bulleted"
                          :to="{ name: 'portalcatalogoexames' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.CATALOGO')}} <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.DEEXAMES')}}</span>
                </b-button>
            </div>

            <div class="column is-3">
                <b-button expanded size="is-small"
                          tag="router-link"
                          icon-left="flask-outline"
                          :to="{ name: 'portalamostras' }"
                          active-class="is-primary">
                        {{$t('NAVBARCONVENIO.AMOSTRAS')}}
                </b-button>
            </div>

            <div class="column is-3">
                <b-button expanded size="is-small"
                          tag="router-link"
                          icon-left="test-tube"
                          :to="{ name: 'portalrelacaoexames' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.EXAMES')}}
                </b-button>
            </div>

            <div class="column is-3" v-if="usuario.habilitarEstoque">
                <b-button expanded
                          tag="router-link"
                          icon-left="archive"
                          :to="{ name: 'portalestoque' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.ESTOQUE')}}
                </b-button>
            </div>

            <div class="column is-3">
                <b-button expanded size="is-small"
                          tag="router-link"
                          icon-left="file-document-outline"
                          :to="{ name: 'portalresultados' }"
                          active-class="is-primary">
                    {{$t('NAVBARCONVENIO.RESULTADOS')}}
                </b-button>
            </div>
        </div>


        <div class="columns is-mobile">

            <div class="column">
                <b-button expanded
                          tag="router-link"
                          icon-left="alert-circle-outline"
                          :to="{ name: 'portalavisos' }"
                          :disabled="avisosCount != 0 ? false : true"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.AVISOS')}}</span>
                    
                    <b-tag v-if="avisosCount > 0" rounded type="is-info tag-notify" style="padding: 0.6rem;"> <span style="font-size: 15px; font-weight: 600;"> {{ avisosCount }} </span> </b-tag>
                </b-button>
            </div>

            <div class="column">
                <b-button expanded
                          tag="router-link"
                          icon-left="refresh"
                          :to="{ name: 'portalrecoletas' }"
                          :disabled="recoletasCount != 0 ? false : true"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.RECOLETA')}}</span>
                    
                    <b-tag v-if="recoletasCount > 0" rounded type="is-info tag-notify" style="padding: 0.6rem;"> <span style="font-size: 15px; font-weight: 600;"> {{ recoletasCount }} </span> </b-tag>
                </b-button>
            </div>

            <div class="column">
                <b-button expanded
                          tag="router-link"
                          icon-left="alarm-multiple"
                          :to="{ name: 'portalexamesatrasados' }"
                          :disabled="examesAtrasosCount != 0 ? false : true"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.REMARCACAO')}}</span>
                    
                    <b-tag v-if="examesAtrasosCount > 0" rounded type="is-dark tag-notify"> {{ examesAtrasosCount }} </b-tag>
                </b-button>
            </div>

            <div class="column">
                <b-button expanded
                          tag="router-link"
                          icon-left="bullhorn-outline"
                          :to="{ name: 'portalvalorcritico' }"
                          :disabled="valorCriticoReportadoCount != 0 ? false : true"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.VALORESCRIT')}}</span>
                    
                    <b-tag v-if="valorCriticoReportadoCount > 0" rounded type="is-dark tag-notify"> {{ valorCriticoReportadoCount }} </b-tag>
                </b-button>
            </div>

            <div class="column">
                <b-button expanded
                          tag="router-link"
                          icon-left="cog-sync-outline"
                          :to="{ name: 'portalintegracao' }"
                          :disabled="integracaoCount != 0 ? false : true"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.INTEGRACAO')}}</span>
                    
                    <b-tag v-if="integracaoCount > 0" rounded type="is-dark tag-notify"> {{ integracaoCount }} </b-tag>
                </b-button>
            </div>

              <div class="column" v-if="(usuario.exibeFatura && !usuario.escondePrecos)">
                <b-button expanded
                          tag="router-link"
                          icon-left="currency-usd"
                          :to="{ name: 'portalfaturas', params: {model: model}}"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.FATURAS')}}</span>
                </b-button>
            </div>

              <div class="column" >
                <b-button expanded
                          tag="router-link"
                          icon-left="folder-cog-outline"
                          :to="{ name: 'portalcadastros', params: {model: model}}"
                          active-class="is-primary">
                    <span class="is-hidden-mobile">{{$t('NAVBARCONVENIO.CADASTROS')}}</span>
                </b-button>
            </div>            
        </div>        

    </section>
</template>

<script>
    /* eslint-disable @typescript-eslint/camelcase */
    import { mapState } from 'vuex'
    import Vue from 'vue';
    /* import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2'; */
     
    export default {
        data(){
            return {
                /* tawkToEnabled: false, */
                avisosCount: 0,
                recoletasCount: 0,
                examesAtrasosCount: 0,
                valorCriticoReportadoCount: 0,
                integracaoCount: 0,
            }
        },        
        computed: {            
            ...mapState([
                'usuario'
            ])          
        },
        methods: {
            /*
            setupTawkTo(){
				if (this.usuario) {
					console.warn('tawk.to: setting current user...');
                    // window.Tawk_API.onLoad = function(){
                    //     const attributes = {
                    //         'name'  : 'Name',
                    //         'email' : 'email@email.com',
                    //         'hash'  : 'hash value',
                    //         'id'    : '09999324',
                    //         'store' :   'mystore'
                    //     }
                    //     console.log("agendando setup tawk to", attributes)
                    //     setTimeout( () => {
                    //         console.log("iniciando setup tawk to", attributes)
                    //         window.Tawk_API.setAttributes(attributes, function(error){
                    //             console.oo
                    //         });
                    //         console.log("finalizando setup tawk to", attributes)
                    //     },5000)
                    // }
				}
			}, */
            carregarAvisosCount(){
                this.$http.get('/api/portal/AvisosCount')
                    .then(res => {
                        this.avisosCount = res.data;
                        this.loadingAvisosCount = false;
                    });
            },
            carregarRecoletasCount(){
                this.$http.get('/api/portal/RecoletasCount')
                    .then(res => {
                        this.recoletasCount = res.data;
                        this.loadingRecoletasCount = false;
                    });
            },
            carregarExamesAtrasadosCount(){
                this.$http.get('/api/portal/ExamesAtrasosCount')
                    .then(res => {
                        this.examesAtrasosCount = res.data;
                        this.loadingExamesAtrasadosCount = false;
                    });
            },
            carregarValorCriticoReportadoCount(){
                this.$http.get('/api/portal/ValorCriticoReportadoCount')
                    .then(res => {
                        this.valorCriticoReportadoCount = res.data;
                        this.loadingValorCriticoReportadoCount = false;
                    });
            }, 
            carregarIntegracaoCount(){
                this.$http.get('/api/portal/IntegracaoCount')
                    .then(res => {
                        this.integracaoCount = res.data;
                        this.loadingIntegracaoCount = false;
                    });
            },       
        },
        /* async beforeMount() {
            if(this.usuario.tipos[0] == 'Convenio'){
                this.tawkToEnabled = true;
            }
            if(this.tawkToEnabled) {
                window.Tawk_API = window.Tawk_API || {};
                Vue.use(TawkMessengerVue, {
                    propertyId : '663cc37107f59932ab3da6db',
                    widgetId : '1htelmtfc'
                }); 
                this.setupTawkTo();
            }
        }, */
        /* mounted() {
            if(this.tawkToEnabled) {
                this.$tawkMessenger.$on('load', () => {
                        const attributes = {
                            'name'  : 'Name',
                            'email' : 'email@email.com',
                            'hash'  : 'hash value',
                            'id'    : '09999324',
                            'store' :   'mystore'
                        }
                        console.log("agendando setup tawk to", attributes)
                        setTimeout( () => {
                            console.log("iniciando setup tawk to", attributes)
                            window.Tawk_API.setAttributes(attributes, function(error){
                                console.log(error)
                            });
                            console.log("finalizando setup tawk to", attributes)
                        },5000)
                })
            }
        }, */

        created() {
                            
            this.carregarAvisosCount();
            this.carregarRecoletasCount();
            this.carregarExamesAtrasadosCount();
            this.carregarIntegracaoCount();
            this.carregarValorCriticoReportadoCount();
        }        
    }
</script>